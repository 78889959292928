import questionType from '../questionType'
import orderBy from 'lodash/orderBy'
import get from 'lodash/get'
import xor from 'lodash/xor'
import has from 'lodash/has'
import { QUESTION_TYPES } from '@platform-shared/constants'

export default {
  mixins: [questionType],
  computed: {
    orderedOptions() {
      return orderBy(
        this.question.answerOptions,
        ['displayOrder', 'score'],
        'asc'
      )
    },
    isHorizontal() {
      return (
        get(this.question, 'configJson.displayOrientation') === 'horizontal'
      )
    },
    isVertical() {
      return get(this.question, 'configJson.displayOrientation') === 'vertical'
    },
    isNumberScale() {
      return has(this.question, 'answerOptions[0].score')
    },
  },
  methods: {
    handleSelect(id) {
      switch (this.question.type) {
        case QUESTION_TYPES.LINEAR_SCALE:
        case QUESTION_TYPES.RADIO_BUTTONS:
          return this.$emit('input', [id])

        case QUESTION_TYPES.CHECKBOXES:
          return this.$emit('input', xor(this.value, [id]))
      }
    },
  },
}
