import _get from 'lodash/get'
import { mapGetters } from 'vuex'

export default {
  props: {
    option: {
      type: Object,
      required: true,
    },
    value: {
      type: Array,
      required: true,
    },
    isHorizontal: {
      type: Boolean,
      default: false,
    },
    isVertical: {
      type: Boolean,
      default: false,
    },
    isNumberScale: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters('currentQuestionnaire', ['screenWidth']),
    ...mapGetters('member', ['preferredLanguageCd']),
    selected() {
      return this.value.includes(this.option.id)
    },
    optionText() {
      const questionnaireLanguage = this.preferredLanguageCd

      return _get(
        this.option.option,
        questionnaireLanguage.toLowerCase(),
        _get(this.option.option, 'en', '')
      )
    },
    icon() {
      return _get(this.option, 'iconName')
    },
  },
}
