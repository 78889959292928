<template>
  <div class="w-250 mx-auto">
    <FormInput
      v-model="inputValue"
      :name="question.id"
      :placeholder="question.placeholder"
      :hasError="hasError"
    />
  </div>
</template>
<script>
import FormInput from '../../../common/forms/FormInput'
import inputMixin from '@platform-shared/mixins/questionnaire/question-types/input'

export default {
  components: { FormInput },
  mixins: [inputMixin],
}
</script>
