<template>
  <div>
    <BadQuestionnaireRoute v-if="badUrl" />
    <Spinner v-else-if="isLoading" />
    <div v-else-if="!!questionnaireId && !!questionnaire">
      <Modal :active="showExitScreen" @close="hideExit">
        <ExitModal @continueQuestionnaire="hideExit" />
      </Modal>
      <StartScreen
        v-if="showStartScreen"
        :skippable="questionnaire.settings.meta.skippable"
        @skip="handleSkip"
        @start="handleStart"
        @showExit="showExit"
      />
      <Group
        v-if="showGroupScreen"
        :key="currentGroup.id"
        :group="currentGroup"
        :exitable="questionnaire.settings.meta.skippable"
        @showExit="showExit"
      />
      <Processing
        v-else-if="showProcessingScreen"
        @finish="handleEndProcessing"
      />
      <Success
        v-else-if="showSuccessScreen"
        :hasSatisfactionSurvey="hasSatisfactionSurvey"
        @finish="close"
      />
    </div>
    <Agreements />
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import {
  FEATURES,
  QUESTIONNAIRE_SCREEN_TYPES,
  QUESTIONNAIRE_TYPES,
  QUESTIONNAIRE_STATUS,
} from '@platform-shared/constants'
import get from 'lodash/get'
import isUndefined from 'lodash/isUndefined'
import Group from './Group'
import StartScreen from './StartScreen'
import Processing from './Processing'
import Success from './Success'
import ExitModal from './ExitModal'
import BadQuestionnaireRoute from '../404.vue'
import Spinner from '@components/common/Spinner'
import Modal from '@components/common/Modal'
import Agreements from '@components/views/Agreements'

export default {
  components: {
    Group,
    Processing,
    Success,
    ExitModal,
    BadQuestionnaireRoute,
    Spinner,
    StartScreen,
    Modal,
    Agreements,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      badUrl: false,
      showExitScreen: false,
    }
  },
  computed: {
    ...mapGetters('client', ['hasFeature']),
    ...mapGetters('currentQuestionnaire', [
      'currentGroup',
      'currentScreen',
      'questionnaireId',
    ]),
    ...mapGetters('questionnaire', ['questionnaireByType', 'isLoading']),
    questionnaireType() {
      return QUESTIONNAIRE_TYPES[this.type.toUpperCase()]
    },
    questionnaire() {
      if (isUndefined(this.questionnaireType)) return undefined
      return this.questionnaireByType(this.questionnaireType)
    },
    showStartScreen() {
      return (
        this.currentScreen === QUESTIONNAIRE_SCREEN_TYPES.START ||
        this.currentScreen === QUESTIONNAIRE_SCREEN_TYPES.WELCOME_BACK
      )
    },
    showGroupScreen() {
      return this.currentScreen === QUESTIONNAIRE_SCREEN_TYPES.GROUP
    },
    showProcessingScreen() {
      return this.currentScreen === QUESTIONNAIRE_SCREEN_TYPES.PROCESS
    },
    showSuccessScreen() {
      return this.currentScreen === QUESTIONNAIRE_SCREEN_TYPES.SUCCESS
    },
    hasSatisfactionSurvey() {
      return (
        this.questionnaire.type === QUESTIONNAIRE_TYPES.HRA &&
        this.hasFeature(FEATURES.HRA_SAT)
      )
    },
  },
  created() {
    if (isUndefined(this.questionnaireType)) {
      this.badUrl = true
    } else if (isUndefined(this.questionnaire)) {
      this.getQuestionnairesByType(this.questionnaireType).then(() => {
        if (isUndefined(this.questionnaire)) {
          this.badUrl = true
        } else {
          this.startAndActivate()
        }
      })
    } else {
      this.startAndActivate()
    }
  },
  methods: {
    ...mapActions('healthActions', ['invalidateHealthActions']),
    ...mapActions('currentQuestionnaire', {
      activate: 'activate',
      skip: 'skip',
      endProcessing: 'endProcessing',
      begin: 'start',
      complete: 'complete',
    }),
    ...mapActions('questionnaire', ['start', 'getQuestionnairesByType']),
    startAndActivate() {
      if (get(this.questionnaire, 'status') !== QUESTIONNAIRE_STATUS.STARTED) {
        this.start(this.questionnaire).then(() => {
          this.activate(this.questionnaire)
        })
      } else {
        this.activate(this.questionnaire)
      }
    },
    handleSkip() {
      if (!this.exitScreen) {
        this.close()
      }

      this.showExitScreen = true
    },
    handleStart() {
      this.begin()
    },
    close() {
      this.complete().then(() => this.$router.push('/'))
    },
    handleEndProcessing() {
      this.endProcessing()
    },
    showExit() {
      this.showExitScreen = true
    },
    hideExit() {
      this.showExitScreen = false
    },
  },
}
</script>
