import questionType from './questionType'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'

export default {
  mixins: [questionType],
  data() {
    return {
      inputValue: this.value[0]
        ? format(parseISO(this.value[0]), 'yyyy-MM-dd')
        : null,
    }
  },
  watch: {
    inputValue(val) {
      this.$emit('input', [val])
    },
  },
}
