import { mapGetters } from 'vuex'
import _get from 'lodash/get'
import _mergeWith from 'lodash/mergeWith'
import { QUESTION_TYPES } from '@platform-shared/constants'

export default {
  props: {
    question: {
      type: Object,
      required: true,
    },
    value: {
      type: Array,
      required: true,
    },
    isLast: {
      type: Boolean,
      default: false,
    },
    isFirst: {
      type: Boolean,
      default: false,
    },
    verticalNumbers: {
      type: Boolean,
      default: false,
    },
    isErrorState: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputValue: this.value,
    }
  },
  computed: {
    ...mapGetters('currentQuestionnaire', [
      'isNumbered',
      'questionnaire',
      'totalQuestions',
      'questionNumberById',
      'questionnaireLength',
      'questionnaireSources',
    ]),
    ...mapGetters('member', ['preferredLanguageCd']),
    questionNumber() {
      return this.questionNumberById(this.question.id)
    },
    languageCd() {
      return this.preferredLanguageCd.toLowerCase()
    },
    localeQuestion() {
      return _mergeWith(
        {},
        _get(this.question, ['locales', 'en'], {}),
        _get(this.question, ['locales', this.languageCd], {}),
        (a, b) => (b === null ? a : undefined)
      )
    },
    title() {
      return _get(this.localeQuestion, 'text', '')
    },
    subtitle() {
      return _get(this.localeQuestion, 'secondaryText', '')
    },
    helperText() {
      return _get(this.localeQuestion, 'helperText', '')
    },
    isSelectable() {
      return [
        QUESTION_TYPES.RADIO_BUTTONS,
        QUESTION_TYPES.CHECKBOXES,
        QUESTION_TYPES.LINEAR_SCALE,
      ].includes(this.question.type)
    },
    sources() {
      return _get(this.question, 'sources', [])
    },
    sourceReferences() {
      return this.sources
        .map((questionSource) => {
          const idx = this.questionnaireSources.findIndex(
            (source) => source.id === questionSource.id
          )

          return Array(idx + 1)
            .fill('*')
            .join('')
        })
        .filter((sourceIndex) => sourceIndex !== 0)
        .join(',')
    },
  },
  watch: {
    inputValue(val) {
      this.$emit('answered', { question: this.question.id, answer: val })
      this.$emit('input', val)
    },
  },
}
