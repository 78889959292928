<template>
  <div class="w-4/12 flex justify-center items-center p-5">
    <input
      :id="option.id"
      class="sr-only"
      type="checkbox"
      :name="name"
      :value="option.id"
      :checked="selected"
      :aria-invalid="hasError"
      @input="$emit('choose', option.id)"
    />
    <label
      :for="option.id"
      :aria-label="optionText"
      class="flex p-16 rounded-md justify-between cursor-pointer flex-1 h-full items-center"
      :class="{
        'bg-grey-50': !selected,
        'bg-black text-white': selected,
      }"
    >
      <p
        :class="{
          'w-10/12': selected,
          'w-full': !selected,
        }"
      >
        {{ optionText }}
      </p>
      <Icon v-if="selected" iconClass="fas fa-check" />
    </label>
  </div>
</template>
<script>
import checkboxAnswerMixin from '@platform-shared/mixins/questionnaire/question-types/selectable/checkboxAnswer'

export default {
  mixins: [checkboxAnswerMixin],
}
</script>

<style scoped lang="postcss">
:focus + label {
  @apply shadow-outline;
}
</style>
