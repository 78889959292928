var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex w-full mx-auto",class:[
    _vm.questionClass,
    {
      'mt-20': _vm.isFirst,
      'errored-question': _vm.hasError,
    },
  ]},[(_vm.questionnaireLength === 'LONG' && _vm.isNumbered)?_c('div',{staticClass:"flex-col justify-center mr-12"},[_c('div',{staticClass:"w-35 h-35 mt-50 rounded-full bg-purple-500 flex items-center justify-center text-white"},[_vm._v(" "+_vm._s(_vm.questionNumber)+" ")]),(!_vm.isLast)?_c('div',{staticClass:"w-2 h-full bg-purple-500 mx-auto"}):_vm._e()]):_vm._e(),_c('fieldset',{staticClass:"w-full flex"},[_c('div',{staticClass:"w-full",class:{
        'border-b border-grey-200': !_vm.isLast,
        'py-50': _vm.questionnaireLength === 'LONG',
      }},[(_vm.title)?_c('div',[_c('h2',{staticClass:"font-semibold mt-5 text-xl"},[_vm._v(" "+_vm._s(_vm.title)+_vm._s(_vm.sourceReferences)+" ")]),(_vm.isSelectable)?_c('legend',{staticClass:"sr-only"},[_vm._v(" "+_vm._s(_vm.title)+_vm._s(_vm.sourceReferences)+" ")]):(!_vm.isSelectable)?_c('label',{staticClass:"sr-only",attrs:{"for":_vm.question.id}},[_vm._v(" "+_vm._s(_vm.title)+_vm._s(_vm.sourceReferences)+" ")]):_vm._e(),(_vm.hasError)?_c('p',{staticClass:"text-red-500",attrs:{"id":`error-${_vm.question.id}`}},[_vm._v(" "+_vm._s(_vm.$t('questionnaire.required_text'))+" ")]):_vm._e()]):_vm._e(),(_vm.question.optional)?_c('p',{staticClass:"font-normal text-sm text-grey-500 mt-5"},[_vm._v(" "+_vm._s(_vm.$t('questionnaire.optional'))+" ")]):_vm._e(),(_vm.subtitle)?_c('p',{staticClass:"text-grey-500 text-sm mt-25"},[_vm._v(" "+_vm._s(_vm.subtitle)+" ")]):_vm._e(),(_vm.helperText)?_c('div',{staticClass:"mt-20"},[_c('a',{staticClass:"font-semibold text-blue-500",on:{"click":function($event){_vm.helperModal = true}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v(_vm._s(String.fromCharCode(0x24d8)))]),_vm._v(" "+_vm._s(_vm.$t('questionnaire.helper_text.title'))+" ")]),_c('Modal',{attrs:{"active":_vm.helperModal,"closeOnBackDrop":""},on:{"close":_vm.closeHelperTextModal}},[_c('HelperTextModal',{attrs:{"helperText":_vm.helperText},on:{"close":_vm.closeHelperTextModal}})],1)],1):_vm._e(),_c('div',{class:{
          'mt-25': _vm.question.type === _vm.questionTypes.DROPDOWN,
          'mt-20': _vm.question.type !== _vm.questionTypes.DROPDOWN,
          'mt-10': _vm.question.id === 'HNA_BP',
        }},[_c(_vm.questionComponent,{tag:"component",staticClass:"inline-block",attrs:{"title":_vm.title,"question":_vm.question,"hasError":_vm.hasError},model:{value:(_vm.inputValue),callback:function ($$v) {_vm.inputValue=$$v},expression:"inputValue"}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }