import questionType from './questionType'

export default {
  mixins: [questionType],
  data() {
    if (!this.value[0]) {
      return {
        systolic: '',
        diastolic: '',
        isUnknown: false,
      }
    }

    const [systolic, diastolic] = this.value[0].split('/')

    return {
      systolic: diastolic ? systolic : '',
      diastolic: diastolic ? diastolic : '',
      isUnknown: !diastolic,
    }
  },
  computed: {
    bp() {
      if (this.systolic && this.diastolic)
        return `${this.systolic}/${this.diastolic}`
      return ''
    },
  },
  watch: {
    bp() {
      this.$emit('input', [this.bp])
    },
    isUnknown(val) {
      if (val) {
        this.$emit('input', ['NA'])
      }
    },
  },
  methods: {
    handleCheck({ value }) {
      if (value) {
        this.systolic = ''
        this.diastolic = ''
      }
      this.isUnknown = value
    },
  },
}
