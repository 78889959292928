<template>
  <div class="mb-90">
    <header>
      <div class="border-b border-grey-100">
        <div class="container flex items-center justify-between h-60">
          <img
            :src="clientLogo"
            :alt="`${this.$settings.NAME} Logo`"
            class="mt-5"
            style="width: 110px"
          />
          <button
            class="text-xl p-2"
            :aria-label="$t('common.exit')"
            @click="showExit"
          >
            <CloseIcon :title="$t('common.exit')" />
          </button>
        </div>
      </div>
      <div class="bg-grey-50">
        <div
          v-if="!!sectionTitle"
          class="container flex items-center justify-center h-90 mb-20"
        >
          <i
            v-if="sectionIcon"
            class="text-white text-2xl mr-10 w-50 h-50 flex items-center justify-center rounded-full"
            :class="[sectionIcon, sectionIconBackground]"
            :style="`background-color: ${sectionIconBackground}`"
            aria-hidden="true"
          ></i>
          <h1 class="font-bold h5">{{ $t(sectionTitle) }}</h1>
        </div>
      </div>
    </header>
    <main ref="questionList">
      <div v-if="questionnaireLength === 'SHORT'" class="w-full flex mt-60">
        <div
          class="bg-purple-500 flex-1"
          :class="{ 'h-2': !isFirstGroup, 'h-0': isFirstGroup }"
        />
        <div
          class="bg-purple-500 w-full"
          :class="[indicatorClass, { 'h-2': !isLastGroup, 'h-0': isLastGroup }]"
        >
          <div
            class="h-30 w-30 -mt-15 flex justify-center items-center rounded-full bg-purple-500 text-white"
          >
            {{ currentGroupId }}
          </div>
        </div>
        <div
          class="bg-purple-500 flex-1"
          :class="{ 'h-2': !isLastGroup, 'h-0': isLastGroup }"
        />
      </div>
      <Question
        v-for="(question, idx) in questions"
        :key="question.id"
        v-model="answers[question.id]"
        :question="question"
        :isLast="idx === totalQuestions - 1"
        :isFirst="idx === 0"
        :isErrorState="answersHasError && !answers[question.id].length"
        @answered="updateAnswers"
      />
      <div class="w-full mx-auto max-w-4xl">
        <p
          v-for="(source, idx) in superscriptedSources"
          :key="`source-${idx}`"
          class="mt-10"
        >
          <Markdown :source="`\\${source}`" />
        </p>
      </div>
    </main>
    <footer>
      <div
        class="py-16 px-20 bg-white border-t border-grey-200 fixed bottom-0 w-full z-0 flex justify-between"
      >
        <div class="w-200 h-40 flex items-center">
          <HMButton
            v-if="canGoBack"
            class="w-full"
            size="x-small"
            rounded="full"
            color="white"
            outlined
            @click="back"
          >
            {{ $t('common.back_btn') }}
          </HMButton>
        </div>
        <div
          style="max-width: 500px;"
          class="w-500 flex flex-col justify-center w-full mx-16"
        >
          <p v-if="answersHasError" class="text-center text-red-500 mb-4 h6">
            {{ $t('questionnaire.group.answer_error') }}
          </p>
          <p v-if="!isVariableLength" class="text-center mb-8">
            {{
              questionnaireLength === 'LONG'
                ? $t('questionnaire.progress.sections', [
                    currentGroupId - 1,
                    totalGroups,
                  ])
                : $t('questionnaire.progress.questions', [
                    currentGroupId - 1,
                    totalGroups,
                  ])
            }}
          </p>
          <ProgressBar
            small
            :percentage="((currentGroupId - 1) / totalGroups) * 100"
          />
        </div>
        <div class="w-200 flex items-center">
          <HMButton class="w-full" size="x-small" rounded="full" @click="next">
            {{ nextText }}
            <Icon v-if="!isLastGroup" iconClass="fas fa-check ml-8" />
          </HMButton>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
import _get from 'lodash/get'
import groupMixin from '@platform-shared/mixins/questionnaire/group'
import Question from './Question'
import CloseIcon from '@components/common/CloseIcon'
import ProgressBar from '@components/common/ProgressBar'
import Markdown from '@components/common/Markdown'

export default {
  components: { Question, CloseIcon, ProgressBar, Markdown },
  mixins: [groupMixin],
  computed: {
    clientLogo() {
      return `${this.$settings.S3_ROOT}/logo_primary.png`
    },
    indicatorClass() {
      const screenWidth = _get(
        this.questionnaire,
        'settings.meta.screenWidth',
        'normal'
      )

      const classMap = {
        small: 'max-w-md',
        normal: 'max-w-2xl',
        large: 'max-w-4xl',
      }

      return classMap[screenWidth]
    },
    nextText() {
      return this.$t(
        this.isLastGroup ? 'questionnaire.submit_survey' : 'common.ok'
      )
    },
  },
  methods: {
    scrollTop() {
      window.scrollTo(0, 0)
    },
    back() {
      this.handleBack()
      this.scrollTop()
    },
    next() {
      this.answerQuestions(this.formatAnswers())
        .then(this.scrollTop)
        .catch(() => {
          const erroredQuestions = this.$refs.questionList.getElementsByClassName(
            'errored-question'
          )
          if (erroredQuestions.length > 0) {
            const firstErroredQuestion = erroredQuestions[0]
            firstErroredQuestion.scrollIntoView()
            firstErroredQuestion
              .querySelectorAll(
                'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
              )[0]
              .focus()
          }
        })
    },
    showExit() {
      this.$emit('showExit')
    },
  },
}
</script>
