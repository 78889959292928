const questionType = {
  props: {
    question: {
      type: Object,
      required: true,
    },
    value: {
      type: Array,
      required: true,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
  },
}

export default questionType
