import _get from 'lodash/get'
import { QUESTIONNAIRE_TYPES } from '../constants'

const questionnaireStrings = {
  [QUESTIONNAIRE_TYPES.FTE]: {
    start: {
      title: 'questionnaire.get_rewarded_healthy_habits',
      subtitle: 'questionnaire.pays_to_be_healthy',
      body: 'questionnaire.earn_rewards_healthy_actions',
      description: 'questionnaire.before_begin_ask_questions',
      startButton: 'questionnaire.lets_get_started',
    },
    nonRewardsStart: {
      title: 'questionnaire.congrats_healthy_habits',
      subtitle: 'questionnaire.healthier_life_first_step',
      body: 'questionnaire.start_health_assistant',
      description: 'questionnaire.before_begin_ask_questions_yourself',
      startButton: 'questionnaire.lets_get_started',
    },
    welcomeBack: {
      title: 'questionnaire.welcome_back',
      subtitle: 'questionnaire.pays_to_be_healthy',
      body: 'questionnaire.earn_rewards_gift_cards',
      description: 'questionnaire.before_begin_ask_questions',
      startButton: 'questionnaire.continue',
    },
    process: {
      title: 'questionnaire.awesome_hang_tight',
      messages: [
        'questionnaire.retrieving',
        'questionnaire.gathering',
        'questionnaire.finalizing',
      ],
    },
    success: {
      title: 'questionnaire.all_set',
      subtitle: 'questionnaire.time_to_earn',
      description: 'questionnaire.customized_experience',
      finishButton: 'questionnaire.lets_begin',
    },
    nonRewardsSuccess: {
      title: 'questionnaire.ready_start',
      description: 'questionnaire.click_button_health_assistant',
      finishButton: 'questionnaire.lets_begin',
    },
    exit: {
      title: 'questionnaire.save_for_later',
      description: 'questionnaire.finish_come_back',
      confirmButton: 'questionnaire.exit_save_later',
      cancelButton: 'questionnaire.cancel',
    },
  },
  [QUESTIONNAIRE_TYPES.HRA]: {
    process: {
      title: 'questionnaire.awesome_hang_tight',
      messages: [
        'questionnaire.retrieving',
        'questionnaire.gathering',
        'questionnaire.finalizing',
      ],
    },
    success: {
      title: 'questionnaire.sweet_all_done',
      subtitle: 'questionnaire.now_provide_insights',
      description: 'questionnaire.look_forward_inbox',
      finishButton: 'common.done_btn',
    },
    exit: {
      title: 'questionnaire.are_you_sure_exit',
      description: 'questionnaire.save_answers_come_back',
      confirmButton: 'questionnaire.yes_exit_questionnaire',
      cancelButton: 'questionnaire.no_continue_questionnaire',
    },
  },
  [QUESTIONNAIRE_TYPES.HRA_SAT]: {
    title: 'questionnaire.post_survey',
    success: {
      title: 'questionnaire.all_set',
      subtitle: 'questionnaire.thanks_for_feedback',
      description: 'questionnaire.your_feedback_important',
      finishButton: 'questionnaire.finish_and_close',
    },
    exit: {
      title: 'questionnaire.are_you_sure_exit',
      description: 'questionnaire.take_any_time_feedback',
      confirmButton: 'common.exit',
      cancelButton: 'questionnaire.cancel',
    },
  },
  [QUESTIONNAIRE_TYPES.MOCK_CAHPS]: {
    title: 'questionnaire.member_satisfaction_survey',
    success: {
      title: 'questionnaire.all_set',
      subtitle: 'questionnaire.thanks_for_feedback',
      description: 'questionnaire.your_feedback_important',
      finishButton: 'questionnaire.finish_and_close',
    },
    exit: {
      title: 'questionnaire.are_you_sure_exit',
      description: 'questionnaire.prompt_future_feedback',
      confirmButton: 'questionnaire.yes_exit_survey',
      cancelButton: 'questionnaire.no_continue',
    },
  },
}

const defaultQuestionnaireStrings = {
  title: 'questionnaire.survey',
  success: {
    title: 'questionnaire.all_set',
    subtitle: 'questionnaire.thanks_for_feedback',
    description: 'questionnaire.your_feedback_important',
    finishButton: 'questionnaire.finish_and_close',
  },
  exit: {
    title: 'questionnaire.are_you_sure_exit',
    description: '',
    confirmButton: 'questionnaire.yes_exit_survey',
    cancelButton: 'questionnaire.no_continue',
  },
}

const getStringByQuestionnaireTypeAndKey = (type, key) => {
  const combinedKey = [type, key].join('.')
  const defaultString = _get(defaultQuestionnaireStrings, key, '')
  const specificString = _get(questionnaireStrings, combinedKey, defaultString)

  return specificString
}

export const getQuestionnaireTitleByQuestionnaireType = (type) =>
  getStringByQuestionnaireTypeAndKey(type, 'title')
export const getStartScreenStringsByQuestionnaireType = (type) => ({
  title: getStringByQuestionnaireTypeAndKey(type, 'start.title'),
  subtitle: getStringByQuestionnaireTypeAndKey(type, 'start.subtitle'),
  body: getStringByQuestionnaireTypeAndKey(type, 'start.body'),
  description: getStringByQuestionnaireTypeAndKey(type, 'start.description'),
  startButton: getStringByQuestionnaireTypeAndKey(type, 'start.startButton'),
})
export const getNonRewardsStartScreenStringsByQuestionnaireType = (type) => ({
  title: getStringByQuestionnaireTypeAndKey(type, 'nonRewardsStart.title'),
  subtitle: getStringByQuestionnaireTypeAndKey(
    type,
    'nonRewardsStart.subtitle'
  ),
  body: getStringByQuestionnaireTypeAndKey(type, 'nonRewardsStart.body'),
  description: getStringByQuestionnaireTypeAndKey(
    type,
    'nonRewardsStart.description'
  ),
  startButton: getStringByQuestionnaireTypeAndKey(
    type,
    'nonRewardsStart.startButton'
  ),
})
export const getWelcomeBackStringsByQuestionnaireType = (type) => ({
  title: getStringByQuestionnaireTypeAndKey(type, 'welcomeBack.title'),
  subtitle: getStringByQuestionnaireTypeAndKey(type, 'welcomeBack.subtitle'),
  body: getStringByQuestionnaireTypeAndKey(type, 'welcomeBack.body'),
  description: getStringByQuestionnaireTypeAndKey(
    type,
    'welcomeBack.description'
  ),
  startButton: getStringByQuestionnaireTypeAndKey(
    type,
    'welcomeBack.startButton'
  ),
})
export const getExitModalStringsByQuestionnaireType = (type) => ({
  title: getStringByQuestionnaireTypeAndKey(type, 'exit.title'),
  description: getStringByQuestionnaireTypeAndKey(type, 'exit.description'),
  confirmButton: getStringByQuestionnaireTypeAndKey(type, 'exit.confirmButton'),
  cancelButton: getStringByQuestionnaireTypeAndKey(type, 'exit.cancelButton'),
})
export const getProcessingScreenStringsByQuestionnaireType = (type) => ({
  title: getStringByQuestionnaireTypeAndKey(type, 'process.title'),
  messages: getStringByQuestionnaireTypeAndKey(type, 'process.messages'),
})
export const getSuccessStringsByQuestionnaireType = (type) => ({
  title: getStringByQuestionnaireTypeAndKey(type, 'success.title'),
  subtitle: getStringByQuestionnaireTypeAndKey(type, 'success.subtitle'),
  description: getStringByQuestionnaireTypeAndKey(type, 'success.description'),
  finishButton: getStringByQuestionnaireTypeAndKey(
    type,
    'success.finishButton'
  ),
})
export const getNonRewardsSuccessStringsByQuestionnaireType = (type) => ({
  title: getStringByQuestionnaireTypeAndKey(type, 'nonRewardsSuccess.title'),
  subtitle: getStringByQuestionnaireTypeAndKey(
    type,
    'nonRewardsSuccess.subtitle'
  ),
  description: getStringByQuestionnaireTypeAndKey(
    type,
    'nonRewardsSuccess.description'
  ),
  finishButton: getStringByQuestionnaireTypeAndKey(
    type,
    'nonRewardsSuccess.finishButton'
  ),
})
