<template>
  <div
    class="flex w-full mx-auto"
    :class="[
      questionClass,
      {
        'mt-20': isFirst,
        'errored-question': hasError,
      },
    ]"
  >
    <div
      v-if="questionnaireLength === 'LONG' && isNumbered"
      class="flex-col justify-center mr-12"
    >
      <div
        class="w-35 h-35 mt-50 rounded-full bg-purple-500 flex items-center justify-center text-white"
      >
        {{ questionNumber }}
      </div>
      <div v-if="!isLast" class="w-2 h-full bg-purple-500 mx-auto" />
    </div>
    <fieldset class="w-full flex">
      <div
        class="w-full"
        :class="{
          'border-b border-grey-200': !isLast,
          'py-50': questionnaireLength === 'LONG',
        }"
      >
        <div v-if="title">
          <h2 class="font-semibold mt-5 text-xl">
            {{ title }}{{ sourceReferences }}
          </h2>
          <legend v-if="isSelectable" class="sr-only">
            {{ title }}{{ sourceReferences }}
          </legend>
          <label v-else-if="!isSelectable" class="sr-only" :for="question.id">
            {{ title }}{{ sourceReferences }}
          </label>
          <p v-if="hasError" :id="`error-${question.id}`" class="text-red-500">
            {{ $t('questionnaire.required_text') }}
          </p>
        </div>
        <p
          v-if="question.optional"
          class="font-normal text-sm text-grey-500 mt-5"
        >
          {{ $t('questionnaire.optional') }}
        </p>
        <p v-if="subtitle" class="text-grey-500 text-sm mt-25">
          {{ subtitle }}
        </p>
        <div v-if="helperText" class="mt-20">
          <a class="font-semibold text-blue-500" @click="helperModal = true">
            <span aria-hidden="true">{{ String.fromCharCode(0x24d8) }}</span>
            {{ $t('questionnaire.helper_text.title') }}
          </a>
          <Modal
            :active="helperModal"
            closeOnBackDrop
            @close="closeHelperTextModal"
          >
            <HelperTextModal
              :helperText="helperText"
              @close="closeHelperTextModal"
            />
          </Modal>
        </div>
        <div
          :class="{
            'mt-25': question.type === questionTypes.DROPDOWN,
            'mt-20': question.type !== questionTypes.DROPDOWN,
            'mt-10': question.id === 'HNA_BP',
          }"
        >
          <component
            :is="questionComponent"
            v-model="inputValue"
            class="inline-block"
            :title="title"
            :question="question"
            :hasError="hasError"
          />
        </div>
      </div>
    </fieldset>
  </div>
</template>
<script>
import questionMixin from '@platform-shared/mixins/questionnaire/question'
import { QUESTION_TYPES } from '@platform-shared/constants'
import Gender from './question-types/Gender'
import Dropdown from './question-types/Dropdown'
import Input from './question-types/Input'
import Textarea from './question-types/Textarea'
import DateField from './question-types/DateField'
import Selectable from './question-types/selectable/Selectable'
import BloodPressure from './question-types/BloodPressure'
import Cholesterol from './question-types/Cholesterol'
import Modal from '@components/common/Modal'
import HelperTextModal from './HelperTextModal'
import _get from 'lodash/get'

const questionTypeComponents = {
  [QUESTION_TYPES.TEXTBOX]: Input,
  [QUESTION_TYPES.TEXTAREA]: Textarea,
  [QUESTION_TYPES.RADIO_BUTTONS]: Selectable,
  [QUESTION_TYPES.CHECKBOXES]: Selectable,
  [QUESTION_TYPES.LINEAR_SCALE]: Selectable,
  [QUESTION_TYPES.DROPDOWN]: Dropdown,
  [QUESTION_TYPES.DATE_FIELD]: DateField,
}

export default {
  components: { Modal, HelperTextModal },
  mixins: [questionMixin],
  data() {
    return {
      helperModal: false,
    }
  },
  computed: {
    questionTypes() {
      return QUESTION_TYPES
    },
    questionComponent() {
      const questionTypeModule = _get(
        this.question,
        'configJson.questionTypeModule'
      )
      switch (questionTypeModule) {
        case 'BloodPressure':
          return BloodPressure
        case 'Cholesterol':
          return Cholesterol
      }

      switch (this.question.id) {
        case 'GENDER':
          return Gender
        case 'HNA_BP':
          return BloodPressure
      }

      return questionTypeComponents[this.question.type]
    },
    questionClass() {
      const screenWidth = _get(
        this.questionnaire,
        'settings.meta.screenWidth',
        'normal'
      )

      const classMap = {
        small: 'max-w-md',
        normal: 'max-w-2xl',
        large: 'max-w-4xl',
      }

      return classMap[screenWidth]
    },
    hasError() {
      return this.isErrorState && !this.question.optional
    },
  },
  methods: {
    closeHelperTextModal() {
      this.helperModal = false
    },
  },
}
</script>
