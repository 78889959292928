<template>
  <div class="w-full">
    <FormGroup v-slot="{ hasError }" :label="label" :name="name">
      <textarea
        :id="name"
        ref="input"
        v-bind="attrs"
        :name="name"
        :value="value"
        :placeholder="placeholder"
        :rows="rows"
        class="w-full border border-grey-500 rounded bg-white py-12 px-8 text-base leading-none focus:outline-none focus:shadow-outline placeholder-grey-500"
        :class="{ 'border-red-500': hasError }"
        :aria-invalid="hasError"
        @focus.native="focus"
        @input="$emit('input', $event.target.value)"
      />
    </FormGroup>
  </div>
</template>
<script>
import FormGroup from './FormGroup'
import bindAll from '@platform-shared/mixins/bindAll'
export default {
  components: { FormGroup },
  mixins: [bindAll],
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    hasError: {
      type: Boolean,
    },
    label: {
      type: String,
      default: '',
    },
    rows: {
      type: Number,
      default: 5,
    },
  },
  methods: {
    focus() {
      this.$refs.input.focus()
    },
  },
}
</script>
