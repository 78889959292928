import questionType from './questionType'

export default {
  mixins: [questionType],
  data() {
    return {
      inputValue: this.value[0] || '',
    }
  },
  watch: {
    inputValue(val) {
      this.$emit('input', [val])
    },
  },
}
