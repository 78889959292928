<template>
  <div class="container flex flex-col items-center mb-60">
    <h2 v-if="strings.title" class="text-center mt-40">
      {{ $t(strings.title) }}
    </h2>
    <img :src="currentImage" class="mt-20 w-250" />
    <h4 v-if="currentMessage" class="mt-20">{{ $t(currentMessage) }}</h4>
  </div>
</template>
<script>
import size from 'lodash/size'
import { mapGetters } from 'vuex'
import { getProcessingScreenStringsByQuestionnaireType } from '@platform-shared/utils/questionnaireStrings'

export default {
  data() {
    return {
      currentMessageIndex: 0,
      timer: '',
      images: [
        `${require('@assets/images/questionnaire/process-1.png')}`,
        `${require('@assets/images/questionnaire/process-2.png')}`,
        `${require('@assets/images/questionnaire/process-3.png')}`,
      ],
    }
  },
  computed: {
    ...mapGetters('currentQuestionnaire', ['questionnaireType']),
    strings() {
      return getProcessingScreenStringsByQuestionnaireType(
        this.questionnaireType
      )
    },
    currentMessage() {
      const message = this.strings.messages[this.currentMessageIndex]
      const translatedMessage = this.$t(message)
      return `${translatedMessage}...`
    },
    currentImage() {
      return this.images[this.currentMessageIndex % this.images.length]
    },
  },
  created() {
    this.timer = setInterval(this.startSequence, 1000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    startSequence() {
      this.currentMessageIndex++

      const isLast = this.currentMessageIndex === size(this.strings.messages)

      if (isLast) {
        clearInterval(this.timer)
        this.$emit('finish')
      }
    },
  },
}
</script>
