<template>
  <div class="text-center p-20 w-400">
    <h4 class="border-b border-grey-200 pb-10">
      {{ $t('questionnaire.helper_text.title') }}
    </h4>
    <p class="mt-20">{{ helperText }}</p>
    <HMButton class="mt-30 w-200" rounded="full" @click="$emit('close')">
      {{ $t('common.done_btn') }}
    </HMButton>
  </div>
</template>
<script>
export default {
  props: {
    helperText: {
      type: String,
      required: true,
    },
  },
}
</script>
