import { mapGetters } from 'vuex'
import { GENDER } from '@platform-shared/constants'

export default {
  computed: {
    ...mapGetters('member', ['genderName']),
    emoji() {
      return this.genderName === GENDER.F ? '🙋‍♀️' : '🙋‍♂️'
    },
  },
}
