<template>
  <div class="w-250 mx-auto -mt-20">
    <FormDatePicker
      v-model="inputValue"
      :label="question.placeholder"
      :name="question.id"
      format="yyyy-MM-dd"
      :hasError="hasError"
    />
  </div>
</template>

<script>
import FormDatePicker from '../../../common/forms/FormDatePicker'
import datefieldMixin from '@platform-shared/mixins/questionnaire/question-types/datefield'

export default {
  components: { FormDatePicker },
  mixins: [datefieldMixin],
}
</script>
