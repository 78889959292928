import { mapGetters } from 'vuex'
import _get from 'lodash/get'

export default {
  props: {
    option: {
      type: Object,
      required: true,
    },
    value: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('member', ['preferredLanguageCd']),
    optionText() {
      const questionnaireLanguage = this.preferredLanguageCd

      return _get(
        this.option.option,
        questionnaireLanguage.toLowerCase(),
        _get(this.option.option, 'en', '')
      )
    },
    selected() {
      return this.value.includes(this.option.id)
    },
  },
}
