import questionType from './questionType'

export default {
  mixins: [questionType],
  data() {
    if (!this.value[0]) {
      return {
        cholesterol: '',
        isUnknown: false,
      }
    }

    const cholesterol = this.value[0]

    return {
      cholesterol: cholesterol && cholesterol != 'NA' ? cholesterol : '',
      isUnknown: !cholesterol,
    }
  },
  computed: {
    computedCholesterol() {
      return this.cholesterol ? this.cholesterol : ''
    },
  },
  watch: {
    computedCholesterol() {
      this.$emit('input', [this.computedCholesterol])
    },
    isUnknown(val) {
      if (val) {
        this.$emit('input', ['NA'])
      }
    },
  },
  methods: {
    handleCheck({ value }) {
      if (value) {
        this.cholesterol = ''
      }
      this.isUnknown = value
    },
  },
}
