var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-4/12 flex justify-center items-center p-5"},[_c('input',{staticClass:"sr-only",attrs:{"id":_vm.option.id,"type":"checkbox","name":_vm.name,"aria-invalid":_vm.hasError},domProps:{"value":_vm.option.id,"checked":_vm.selected},on:{"input":function($event){return _vm.$emit('choose', _vm.option.id)}}}),_c('label',{staticClass:"flex p-16 rounded-md justify-between cursor-pointer flex-1 h-full items-center",class:{
      'bg-grey-50': !_vm.selected,
      'bg-black text-white': _vm.selected,
    },attrs:{"for":_vm.option.id,"aria-label":_vm.optionText}},[_c('p',{class:{
        'w-10/12': _vm.selected,
        'w-full': !_vm.selected,
      }},[_vm._v(" "+_vm._s(_vm.optionText)+" ")]),(_vm.selected)?_c('Icon',{attrs:{"iconClass":"fas fa-check"}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }