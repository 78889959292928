<template>
  <div class="flex flex-col items-center p-40 w-435">
    <h1 class="font-semibold h5">{{ $t(strings.title) }}</h1>
    <p class="mt-20 text-center">
      {{ $t(strings.description) }}
    </p>
    <HMButton class="mt-20" rounded="full" @click="exit">
      {{ $t(strings.confirmButton) }}
    </HMButton>
    <HMButton
      class="mt-20"
      color="white"
      rounded="full"
      @click="continueQuestionnaire"
    >
      {{ $t(strings.cancelButton) }}
    </HMButton>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { getExitModalStringsByQuestionnaireType } from '@platform-shared/utils/questionnaireStrings'

export default {
  computed: {
    ...mapGetters('currentQuestionnaire', ['questionnaireType']),
    strings() {
      return getExitModalStringsByQuestionnaireType(this.questionnaireType)
    },
  },
  methods: {
    ...mapActions('currentQuestionnaire', ['complete']),
    continueQuestionnaire() {
      this.$emit('continueQuestionnaire')
    },
    exit() {
      this.complete().then(() => this.$router.push('/'))
    },
  },
}
</script>
