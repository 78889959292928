<template>
  <div
    :class="{
      'w-4/12': screenWidth === 'large' && !isHorizontal,
      'w-6/12': screenWidth === 'normal' && !isHorizontal && !isVertical,
      'w-8/12': screenWidth === 'normal' && isVertical,
    }"
  >
    <input
      :id="option.id"
      class="sr-only"
      type="radio"
      :name="name"
      :value="option.id"
      :checked="selected"
      :aria-invalid="hasError"
      @input="$emit('choose', option.id)"
    />
    <label
      :for="option.id"
      :aria-label="optionText"
      class="flex cursor-pointer"
      :class="{
        'mb-10 bg-transparent': !isHorizontal && !isVertical,
        'mb-10': isVertical,

        'mx-5 h-100 w-100 justify-center text-center p-16':
          isHorizontal && !isNumberScale,
        'mx-6 h-45 w-45 justify-center text-center text-xl font-semibold':
          isHorizontal && isNumberScale,
        'rounded-md': isHorizontal && !icon,
        'rounded-md flex-col': isHorizontal && icon,
        'bg-black text-white': isHorizontal && selected,
        ' bg-grey-50': isHorizontal && !selected,
      }"
    >
      <div
        class="flex w-full h-full items-center"
        :class="{
          'rounded-md bg-grey-50 p-16 mx-5': !isHorizontal,
          'flex-col justify-center': isHorizontal,
        }"
      >
        <Icon
          v-if="isHorizontal && icon"
          class="text-4xl"
          :iconClass="iconClass"
        />
        <div class="flex">
          <div
            v-if="!isHorizontal"
            class="flex mr-12 w-18 h-18 bg-white border border-grey-300 rounded-full justify-center self-center"
            :class="{ 'border-blue-500': selected }"
          >
            <div
              v-if="selected"
              class="h-10 w-10 bg-blue-500 rounded-full self-center"
            />
          </div>
        </div>
        <p
          :class="{
            'text-sm font-semibold w-full self-center': isHorizontal,
          }"
        >
          {{ optionText }}
        </p>
      </div>
    </label>
  </div>
</template>
<script>
import radioAnswerMixin from '@platform-shared/mixins/questionnaire/question-types/selectable/radioAnswer'

const iconClasses = {
  grin: 'icon-smile',
  meh: 'icon-indifferent',
  'frown-open': 'icon-unhappy',
  'thumbs-up': 'fas fa-thumbs-up',
  'thumbs-down': 'fas fa-thumbs-down',
}

export default {
  mixins: [radioAnswerMixin],
  computed: {
    iconClass() {
      return iconClasses[this.icon]
    },
  },
}
</script>

<style scoped lang="postcss">
:focus + label {
  @apply shadow-outline;
}
</style>
