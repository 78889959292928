<template>
  <FormTextarea
    v-model="inputValue"
    :name="question.id"
    :placeholder="question.placeholder"
    :hasError="hasError"
  />
</template>
<script>
import FormTextarea from '../../../common/forms/FormTextarea'
import textareaMixin from '@platform-shared/mixins/questionnaire/question-types/textarea'

export default {
  components: { FormTextarea },
  mixins: [textareaMixin],
}
</script>
