<template>
  <div class="container flex flex-col items-center mb-60">
    <h1 v-if="strings.title" class="mt-40 h2">{{ $t(strings.title) }}</h1>
    <img :src="successImagePath" class="mt-20 w-150" alt="" />
    <p v-if="strings.subtitle" class="mt-20 font-semibold">
      {{ $t(strings.subtitle) }}
    </p>
    <p v-if="strings.description" class="mt-20 font-semibold">
      {{ $t(strings.description) }}
    </p>
    <HMButton class="mt-20 w-300" rounded="full" @click="$emit('finish')">
      {{ $t(strings.finishButton) }}
    </HMButton>
    <router-link
      v-if="hasSatisfactionSurvey"
      to="/questionnaire/hra_sat"
      class="mt-20"
      @click.native="loadHraSat"
    >
      <p class="font-semibold">
        {{ $t('questionnaire.go_to_survey') }}
      </p>
    </router-link>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { QUESTIONNAIRE_TYPES } from '@platform-shared/constants'
import isUndefined from 'lodash/isUndefined'
import {
  getSuccessStringsByQuestionnaireType,
  getNonRewardsSuccessStringsByQuestionnaireType,
} from '@platform-shared/utils/questionnaireStrings'

export default {
  props: {
    hasSatisfactionSurvey: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters('currentQuestionnaire', ['questionnaireType']),
    ...mapGetters('questionnaire', ['questionnaireByType']),
    ...mapGetters('rewards', ['usesRewards']),
    successImagePath() {
      return this.questionnaireType === QUESTIONNAIRE_TYPES.HRA
        ? `${require('@assets/images/hra/success.png')}`
        : `${require('@assets/images/questionnaire/success.png')}`
    },
    hraSat() {
      return this.questionnaireByType(QUESTIONNAIRE_TYPES.HRA_SAT)
    },
    strings() {
      if (
        this.questionnaireType === QUESTIONNAIRE_TYPES.FTE &&
        !this.usesRewards
      ) {
        return getNonRewardsSuccessStringsByQuestionnaireType(
          this.questionnaireType
        )
      }
      return getSuccessStringsByQuestionnaireType(this.questionnaireType)
    },
  },
  methods: {
    ...mapActions('currentQuestionnaire', ['activate']),
    ...mapActions('questionnaire', ['getQuestionnairesByType', 'start']),
    loadHraSat() {
      if (isUndefined(this.hraSat)) {
        this.getQuestionnairesByType(QUESTIONNAIRE_TYPES.HRA_SAT).then(
          this.startAndActivateHraSat
        )
      } else {
        this.startAndActivateHraSat()
      }
    },
    startAndActivateHraSat() {
      this.start(this.hraSat).then(() => this.activate(this.hraSat))
    },
  },
}
</script>
