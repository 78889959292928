import { mapGetters } from 'vuex'
import questionType from './questionType'
import get from 'lodash/get'
import flow from 'lodash/fp/flow'
import orderBy from 'lodash/fp/orderBy'
import map from 'lodash/fp/map'

export default {
  mixins: [questionType],
  computed: {
    ...mapGetters('member', ['preferredLanguageCd']),
    questionnaireLanguage() {
      const preferredLanguageCode = this.preferredLanguageCd

      return preferredLanguageCode.toLowerCase()
    },
    orderedOptions() {
      return flow(
        orderBy('displayOrder', 'asc'),
        map((option) => ({
          value: option.id,
          display: get(
            option.option,
            this.questionnaireLanguage,
            get(option.option, 'en', '')
          ),
        }))
      )(this.question.answerOptions)
    },
    title() {
      return get(
        this.question.question,
        this.questionnaireLanguage,
        get(this.question.question, 'en', '')
      )
    },
  },
  methods: {
    handleSelect(id) {
      return this.$emit('input', [id])
    },
  },
}
