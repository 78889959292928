<template>
  <div class="w-250 -mt-20">
    <FormListPicker
      :value="value[0] || null"
      :name="question.id"
      :data="orderedOptions"
      :hasError="hasError"
      @input="handleSelect"
    />
  </div>
</template>

<script>
import FormListPicker from '../../../common/forms/FormListPicker'
import dropdownMixin from '@platform-shared/mixins/questionnaire/question-types/dropdown'

export default {
  components: { FormListPicker },
  mixins: [dropdownMixin],
}
</script>
