<template>
  <div
    class="bg-black rounded-full h-125 w-125 flex flex-col items-center justify-center"
  >
    <p class="h1">{{ emoji }}</p>
    <p class="text-white font-semibold">{{ genderName }}</p>
  </div>
</template>
<script>
import genderMixin from '@platform-shared/mixins/questionnaire/question-types/gender'

export default {
  mixins: [genderMixin],
}
</script>
