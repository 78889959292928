import { mapGetters, mapActions } from 'vuex'
import _isUndefined from 'lodash/isUndefined'
import _mapValues from 'lodash/mapValues'
import _find from 'lodash/find'
import _get from 'lodash/get'
import _omitBy from 'lodash/omitBy'
import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'
import flow from 'lodash/fp/flow'
import get from 'lodash/fp/get'
import getOr from 'lodash/fp/getOr'
import filter from 'lodash/fp/filter'
import map from 'lodash/fp/map'
import includes from 'lodash/fp/includes'
import { QUESTION_TYPES } from '@platform-shared/constants'
import { getQuestionnaireTitleByQuestionnaireType } from '@platform-shared/utils/questionnaireStrings'

export default {
  props: {
    group: {
      type: Object,
      required: true,
    },
    colorTheme: {
      type: String,
      default: 'purple',
    },
  },
  data() {
    return {
      answers: {},
      questions: {},
    }
  },
  computed: {
    ...mapGetters('currentQuestionnaire', [
      'canGoBack',
      'questionnaire',
      'currentGroupPrefillAnswers',
      'isFirstGroup',
      'isLastGroup',
      'totalGroups',
      'currentGroupId',
      'isVariableLength',
      'questionnaireLength',
      'runningAnswers',
      'questionnaireType',
      'questionnaireSources',
    ]),
    ...mapGetters('questionnaire', ['answersHasError']),
    ...mapGetters('member', ['genderName']),
    questionnaireTitle() {
      const questionnaireType = this.questionnaireType.toLowerCase()
      return getQuestionnaireTitleByQuestionnaireType(questionnaireType)
    },
    sectionTitle() {
      return _get(
        this.group,
        'questions[0].sectionTitle',
        this.questionnaireTitle
      )
    },
    sectionIcon() {
      return _get(this.group, 'questions[0].sectionConfigJson.icon', '')
    },
    sectionIconBackground() {
      return _get(this.group, 'questions[0].sectionConfigJson.bgColor', '')
    },
    totalQuestions() {
      return _get(this, 'questions.length', 0)
    },
    superscriptedSources() {
      return this.isLastGroup
        ? this.questionnaireSources.map(
            (source, idx) =>
              `${Array(idx + 1)
                .fill('*')
                .join('')} ${source.source}`
          )
        : []
    },
  },
  methods: {
    ...mapActions('currentQuestionnaire', [
      'answerQuestions',
      'goBack',
      'goNext',
      'updateRunningAnswers',
    ]),
    handleSubmit() {
      this.answerQuestions(this.formatAnswers())
    },
    handleSkip() {
      this.goNext()
    },
    handleBack() {
      this.goBack()
    },
    updateAnswers({ question, answer }) {
      this.updateRunningAnswers({ question, answer })
      this.setQuestions()
    },
    setQuestions() {
      this.questions = flow(
        getOr([], 'questions'),
        filter(({ requirements }) =>
          requirements.length > 0
            ? requirements.some((condition) => {
                const { questionId, answer } = condition
                return flow(
                  get(questionId),
                  map('answer'),
                  includes(answer)
                )(this.runningAnswers)
              })
            : true
        )
      )(this.group)
    },
    prefillAnswers() {
      this.group.questions.map((question) => {
        const currentAnswer = this.group.answers.find(
          (answer) => answer.question === question.id
        )
        const oldAnswers = _get(this.currentGroupPrefillAnswers, question.id)

        // use answer from current attempt first
        if (!_isUndefined(currentAnswer.answers)) {
          this.answers[question.id] = currentAnswer.answers.map(
            (answer) => answer.answer
          )
        }
        // use answers from previous completion
        else if (!_isUndefined(oldAnswers)) {
          this.answers[question.id] = oldAnswers.map((answer) => answer.answer)
          this.updateRunningAnswers({
            question: question.id,
            answer: this.answers[question.id],
          })
        } else {
          this.answers[question.id] = []
        }
      })
    },
    formatAnswers() {
      const formattedAnswers = _mapValues(this.answers, (answer, id) => {
        const question = _find(this.group.questions, { id })

        // unique questions
        switch (question.id) {
          // gender cannot be changed, but still needs to be submitted
          case 'GENDER':
            return [
              _get(
                _find(
                  _get(question, 'answerOptions', []),
                  (answerOption) =>
                    _get(answerOption, 'option.en') === this.genderName
                ),
                'id'
              ),
            ]

          default:
            switch (question.type) {
              case QUESTION_TYPES.DATE_FIELD:
                return _get(answer, 0)
                  ? [format(parseISO(answer[0]), 'yyyy-MM-dd')]
                  : []
              default:
                return answer
            }
        }
      })
      return _omitBy(formattedAnswers, (answer, id) => {
        const question = _find(this.group.questions, { id })
        const formattedAnswer = filter((a) => !!a, answer)
        return question.optional && formattedAnswer.length === 0
      })
    },
  },
  created() {
    this.prefillAnswers()
    this.setQuestions()
  },
}
