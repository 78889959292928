<template>
  <div class="md:w-6/12 mx-auto">
    <div class="flex justify-between">
      <FormInput
        v-model="systolic"
        class="w-1/3"
        size="small"
        :disabled="isUnknown"
        name="systolic"
        :hasError="hasError"
        type="number"
      />
      <div class="flex self-center">
        <p class="text-grey-400 h2">/</p>
      </div>
      <FormInput
        v-model="diastolic"
        class="w-1/3"
        size="small"
        :disabled="isUnknown"
        name="diastolic"
        :hasError="hasError"
        type="number"
      />
      <div class="flex self-center">
        <p class="h4">mmHg</p>
      </div>
    </div>
    <div class="inline-block mt-12 bg-grey-50 py-10 px-20 rounded-md">
      <input
        type="checkbox"
        class="mr-8 inline-block"
        :checked="!!isUnknown"
        @change="handleCheckEvent"
      />
      <p class="inline-block">
        {{ $t('questionnaire.question.blood_pressure') }}
      </p>
    </div>
  </div>
</template>

<script>
import FormInput from '../../../common/forms/FormInput'
import bloodPressureMixin from '@platform-shared/mixins/questionnaire/question-types/bloodPressure'

export default {
  components: { FormInput },
  mixins: [bloodPressureMixin],
  methods: {
    handleCheckEvent(event) {
      this.handleCheck({ value: event.target.checked })
    },
  },
}
</script>
