<template>
  <div
    class="flex w-full flex-wrap"
    :class="[answerClass, { 'flex-col': isVertical }]"
  >
    <component
      :is="answerComponent"
      v-for="option in orderedOptions"
      :key="option.id"
      :option="option"
      :value="value"
      :isHorizontal="isHorizontal"
      :isVertical="isVertical"
      :isNumberScale="isNumberScale"
      :name="question.id"
      :hasError="hasError"
      @choose="handleSelect"
    />
  </div>
</template>
<script>
import selectableMixin from '@platform-shared/mixins/questionnaire/question-types/selectable/selectable'
import CheckboxAnswer from './CheckboxAnswer'
import RadioAnswer from './RadioAnswer'
import { QUESTION_TYPES } from '@platform-shared/constants'

const selectableComponentTypes = {
  [QUESTION_TYPES.LINEAR_SCALE]: RadioAnswer,
  [QUESTION_TYPES.RADIO_BUTTONS]: RadioAnswer,
  [QUESTION_TYPES.CHECKBOXES]: CheckboxAnswer,
}

export default {
  mixins: [selectableMixin],
  computed: {
    answerComponent() {
      return selectableComponentTypes[this.question.type]
    },
    answerClass() {
      if (this.question.type === QUESTION_TYPES.CHECKBOXES) {
        return { 'justify-around': this.isHorizontal }
      }

      return {
        'w-350 mx-auto': !this.isHorizontal || this.isNumberScale,
      }
    },
  },
}
</script>
