<template>
  <div class="mb-60">
    <div class="border-b border-grey-100">
      <div class="container flex items-center justify-between h-60">
        <img
          :src="clientLogo"
          :alt="`${this.$settings.NAME} Logo`"
          class="mt-5"
          style="width: 110px"
        />
        <a class="text-xl p-2" @click="showExit">
          <Icon iconClass="fas fa-times" :title="$t('common.exit')" />
        </a>
      </div>
    </div>
    <div class="mt-90 pt-5 container flex flex-col items-center">
      <h4>{{ $t(strings.title) }}</h4>
      <img
        class="mt-16"
        src="@assets/images/questionnaire/start.png"
        width="216"
      />
      <h6 class="mt-9">{{ $t(strings.subtitle) }}</h6>
      <p class="mt-12 w-400 px-5 text-center mb-25">{{ $t(strings.body) }}</p>
      <p class="w-4/5 px-5 text-center text-sm font-semibold pt-2 pb-8">
        {{ $t(strings.description) }}
      </p>
      <HMButton
        size="small"
        rounded="full"
        class="mt-25 w-300"
        fontWeight="semibold"
        @click="handleStart"
      >
        {{ $t(strings.startButton) }}
      </HMButton>
      <HMButton
        v-if="skippable"
        size="small"
        rounded="full"
        class="mt-20 w-300 px-7"
        fontWeight="semibold"
        color="white"
        @click="handleSkip"
      >
        {{ $t('questionnaire.start_screen.later') }}
      </HMButton>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  getStartScreenStringsByQuestionnaireType,
  getNonRewardsStartScreenStringsByQuestionnaireType,
  getWelcomeBackStringsByQuestionnaireType,
} from '@platform-shared/utils/questionnaireStrings'
import {
  QUESTIONNAIRE_TYPES,
  QUESTIONNAIRE_SCREEN_TYPES,
} from '@platform-shared/constants'

export default {
  props: {
    skippable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('currentQuestionnaire', [
      'questionnaireType',
      'currentScreen',
    ]),
    ...mapGetters('rewards', ['usesRewards']),
    clientLogo() {
      return `${this.$settings.S3_ROOT}/logo_primary.png`
    },
    strings() {
      if (this.currentScreen === QUESTIONNAIRE_SCREEN_TYPES.WELCOME_BACK) {
        return getWelcomeBackStringsByQuestionnaireType(this.questionnaireType)
      }
      if (
        this.questionnaireType === QUESTIONNAIRE_TYPES.FTE &&
        !this.usesRewards
      ) {
        return getNonRewardsStartScreenStringsByQuestionnaireType(
          this.questionnaireType
        )
      }
      return getStartScreenStringsByQuestionnaireType(this.questionnaireType)
    },
  },
  methods: {
    handleSkip() {
      this.$emit('skip')
    },
    handleStart() {
      this.$emit('start')
    },
    showExit() {
      this.$emit('showExit')
    },
  },
}
</script>
