<template>
  <div>
    <FormGroup
      v-slot="{ hasError }"
      :label="label"
      :name="name"
      :helperText="helperText"
    >
      <div
        class="relative border rounded border-grey-400"
        :class="{ 'border-red-500': hasError }"
      >
        <v-date-picker v-model="date" v-bind="attrs">
          <input
            :id="name"
            slot-scope="{ inputProps, inputEvents }"
            :name="name"
            v-bind="inputProps"
            class="w-full px-8 py-12 text-base leading-none bg-white border rounded border-grey-500 pr-30 focus:outline-none focus:shadow-outline placeholder-grey-500"
            :placeholder="placeholder ? placeholder : '--/--/----'"
            :aria-invalid="hasError"
            v-on="inputEvents"
          />
        </v-date-picker>
        <Icon
          iconClass="far fa-calendar-alt"
          class="absolute form-icon text-grey-500"
        />
      </div>
    </FormGroup>
  </div>
</template>
<script>
import FormGroup from './FormGroup'
import bindAll from '@platform-shared/mixins/bindAll'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'

export default {
  components: { FormGroup },
  mixins: [bindAll],
  props: {
    name: {
      type: String,
      required: true,
    },
    hasError: {
      type: Boolean,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    format: {
      type: String,
      required: false,
      default: '',
    },
    value: {
      type: [String, Date],
      required: false,
      default: '',
    },
    helperText: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    let initialValue = null
    if (this.value instanceof Date) initialValue = this.value
    else if (this.value instanceof String) initialValue = parseISO(this.value)

    return {
      date: initialValue,
    }
  },
  watch: {
    date(val) {
      this.$emit('input', this.format && val ? format(val, this.format) : val)
    },
  },
}
</script>
<style scoped>
.form-icon {
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}
</style>
